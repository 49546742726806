<template>
  <div class="common_sub_container">
    <loader v-if="loading" />
    <h2 v-if="!loading">ENCODING</h2>
    <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
    <div class="encoding_form_wrapper" v-if="!loading">
      <div class="sub_sections">
        <ValidationProvider
            name="service"
            rules="required"
            v-slot="{ errors }"
            slim
          >
        <section class="search_section">
          <h5>Service</h5>
            <select v-model="encode.service_id">
              <option value="" disabled>Select Service</option>
              <option v-for="(data,i) in services" :value="data.id" :key="'services'+i">{{data.name}}</option>
            </select>
        </section>
        </ValidationProvider>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <ValidationProvider
                    name="sender name"
                    rules="required|max:30"
                    v-slot="{ errors }"
                    slim
                  >
                  <div class="label_and_element_wrapper">
                      <a
                        class="bt_edit_contact show_addressbook"
                        @click="viewAddressBook('pickup')"
                        v-if="auth().user_type != 'user'"
                      >VIEW ADDRESS BOOK</a>
                    <label>
                      Sender Name
                          <span>*</span>
                    </label>
                    <input type="text" placeholder="Sender Name Character Limit 30" :class="errors[0] ? 'dynamic_data_trigger warning-border':'dynamic_data_trigger'" v-model="encode.pickup_name" :disabled="auth().user_type == 'user'" @focus="show_pickup_suggestion = true" @blur="hideSuggestions" autocomplete="new-password" :maxlength="max">
                    <div class="dynamic_data_wrapper" v-if="show_pickup_suggestion">
                      <span 
                        v-for="(address,i) in filteredPickupAddress" 
                        :key="'pickup_address_'+i"
                        @click="populatePickupAddress(address)"
                        >
                        {{address.name}}
                      </span>
                    </div>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>

              <tr>
                <td>
                  <ValidationProvider
                    name="department name"
                    rules="max:30"
                    v-slot="{ errors }"
                    slim
                  >
                  <div class="label_and_element_wrapper">
                    <label>
                      Department Name
                    </label>
                    <input type="text" placeholder="Department Name Character Limit 30" v-model="encode.pickup_department" :disabled="auth().user_type == 'user'" :class="errors[0] ? 'warning-border':''" autocomplete="new-password":maxlength="max">
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="branch name"
                      rules="max:30"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Branch Name
                    </label>
                    <input type="text" placeholder="Branch Name Character Limit 30" v-model="encode.pickup_branch" :disabled="auth().user_type == 'user'" :class="errors[0] ? 'warning-border':''" autocomplete="new-password" :maxlength="max">
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Cost Center
                      
                    </label>
                    <select v-model="encode.cost_center_id" :disabled="auth().user_type == 'user'">
                      <option v-for="(cost_center,i) in cost_centers" :key="'cost_center_'+i" :value="cost_center.id">
                        {{cost_center.name}}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="company name"
                      rules="max:30"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Company Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Company Name Character Limit 30" v-model="encode.pickup_company" :disabled="auth().user_type == 'user'"  :class="errors[0] ? 'warning-border':''" autocomplete="new-password" :maxlength="max">
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="address 1"
                      rules="required|max:60"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Address 1
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Address 1 Character Limit 60" v-model="encode.pickup_address_1" :disabled="auth().user_type == 'user'" :class="errors[0] ? 'warning-border':''" autocomplete="new-password" :maxlength="max2">
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- <ValidationProvider
                      name="address 2"
                      rules="required|max:60"
                      v-slot="{ errors }"
                      slim
                    > -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Address 2
                      <!-- <span>*</span> -->
                    </label>
                    <input type="text" placeholder="Address 2 Character Limit 60" v-model="encode.pickup_address_2" :disabled="auth().user_type == 'user'"  autocomplete="new-password" :maxlength="max2">
                  </div>
                  <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td>
                <!--   <ValidationProvider
                      name="postal code"
                      rules="max:10"
                      v-slot="{ errors }"
                      slim
                    > -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Postal Code
                    </label>
                    <input type="text" placeholder="Postal Code Character Limit 10" v-model="encode.pickup_postal_code" :disabled="auth().user_type == 'user'" autocomplete="new-password" :maxlength="max1">
                  </div>
                  <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td>
                 <!--  <ValidationProvider
                      name="city"
                      rules="required|max:30"
                      v-slot="{ errors }"
                      slim
                    > -->
                  <div class="label_and_element_wrapper">
                    <label>
                      City
                      <!-- <span>*</span> -->
                    </label>
                    <input type="text" placeholder="City Character Limit 30" v-model="encode.pickup_city" :disabled="auth().user_type == 'user'" autocomplete="new-password" :maxlength="max">
                    
                  </div>
                  <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="suburb"
                      rules="max:30"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Suburb
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Suburb Character Limit 30" v-model="encode.pickup_suburb" :disabled="auth().user_type == 'user'" :class="errors[0] ? 'warning-border':''" autocomplete="new-password":maxlength="max">
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="country"
                      rules="max:30"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Country
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Country Character Limit 30" v-model="encode.pickup_country" :disabled="auth().user_type == 'user'"  :class="errors[0] ? 'warning-border':''" autocomplete="new-password" :maxlength="max">
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="state/province"
                      rules="required|max:30"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      State/Province
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="State/Province Character Limit 30" v-model="encode.pickup_state" :disabled="auth().user_type == 'user'" :class="errors[0] ? 'warning-border':''" autocomplete="new-password" :maxlength="max">
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              
              <tr>
                <td>
                  <ValidationProvider
                      name="phone"
                      rules="max:20"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Phone
                       <span>*</span>
                    </label>
                    <input type="text" placeholder="Phone Character Limit 20" v-model="encode.pickup_phone" :disabled="auth().user_type == 'user'" :class="errors[0] ? 'warning-border':''" autocomplete="new-password" :maxlength="max4">
                    
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="mobile"
                      rules="max:20"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Mobile
                      <span></span>
                    </label>
                    <input type="text" placeholder="Mobile Character Limit 20" v-model="encode.pickup_mobile" :disabled="auth().user_type == 'user'" :class="errors[0] ? 'warning-border':''" autocomplete="new-password" :maxlength="max4">
                    
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="email"
                      rules="required|email|max:60"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Email
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Email Character Limit 60" v-model="encode.pickup_email" :disabled="auth().user_type == 'user'" :class="errors[0] ? 'warning-border':''" autocomplete="new-password":maxlength="max2">
                    
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="no of items"
                      rules="required|integer"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      No. of Items
                      <span>*</span>
                    </label>
                    <input type="number" placeholder="Item Number Maximum Limit 1000" v-model="encode.item_qty"  :class="errors[0] ? 'warning-border':''" min="1" max="1000" autocomplete="new-password">
                    
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="item description"
                      rules="max:70"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Item Description
                      <span></span>
                    </label>
                    <textarea type="text" placeholder="Item Description Character Limit 70" v-model="encode.goods_description" :class="errors[0] ? 'two_line warning-border':'two_line'":maxlength="max3"></textarea>
                    
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="delivery instructions"
                      rules="max:70"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Delivery Instructions
                      <span></span>
                    </label>
                    <textarea :class="errors[0] ? 'two_line warning-border':'two_line'" type="text" placeholder="Delivery Instructions Character Limit 70" v-model="encode.pickup_instructions":maxlength="max3"></textarea>
                    
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
            </table>
          </div>
        </section>
      </div>
      <div class="sub_sections">
        <section class="search_section">
          <h5>Ship To</h5>
          <select v-model="encode.category" >
            <option value="" disabled>Select Category</option>
            <option value="internal">Internal</option>
            <option value="external">External</option>
          </select>
        </section>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <ValidationProvider
                    name="receiver name"
                    rules="required|max:60"
                    v-slot="{ errors }"
                    slim
                  >
                  <div class="label_and_element_wrapper">
                    <a
                      class="bt_edit_contact show_addressbook"
                      @click="viewAddressBook('delivery')"
                    >VIEW ADDRESS BOOK</a>
                    <label>
                      Receiver Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Receiver Name Character Limit 30" :class="errors[0] ? 'dynamic_data_trigger warning-border':'dynamic_data_trigger'" v-model="encode.delivery_name" @focus="show_destination_suggestion = true" @blur="hideSuggestions" autocomplete="new-password" :maxlength="max">
                    
                    <div class="dynamic_data_wrapper" v-if="encode.category && show_destination_suggestion">
                      <span 
                        v-for="(address,i) in filteredDeliveryAddress" 
                        :key="'delivery_address_'+i"
                        @click="populateDeliveryAddress(address)"
                        >
                        {{address.name}}
                      </span>
                    </div>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                    name="department name"
                    rules="max:30"
                    v-slot="{ errors }"
                    slim
                  >
                  <div class="label_and_element_wrapper">
                    <label>
                      Department Name
                    </label>
                    <input type="text" placeholder="Department Name Character Limit 30" v-model="encode.delivery_department" :class="errors[0] ? 'warning-border':''" autocomplete="new-password" :maxlength="max">
                    
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="branch name"
                      rules="max:30"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Branch Name
                    </label>
                    <input type="text" placeholder="Branch Name Character Limit 30" v-model="encode.delivery_branch" :class="errors[0] ? 'warning-border':''" autocomplete="new-password" :maxlength="max">
                    
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="company name"
                      rules="max:30"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Company Name
                    </label>
                    <input type="text" placeholder="Company Name Character Limit 30" v-model="encode.delivery_company" :class="errors[0] ? 'warning-border':''" autocomplete="new-password" :maxlength="max">
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="address 1"
                      rules="required|max:60"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Address 1
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Address 1 Character Limit 60" v-model="encode.delivery_address_1" :class="errors[0] ? 'warning-border':''" autocomplete="new-password"  :maxlength="max2">
                    
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
               <!--    <ValidationProvider
                      name="address 2"
                      rules="required|max:60"
                      v-slot="{ errors }"
                      slim
                    > -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Address 2
                      <!-- <span>*</span> -->
                    </label>
                    <input type="text" placeholder="Address 2 Character Limit 60" v-model="encode.delivery_address_2" autocomplete="new-password"  :maxlength="max2">
                    
                  </div>
                  <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="postal code"
                      rules="max:10"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Postal Code
                    </label>
                    <input type="text" placeholder="Postal Code Character Limit 10" v-model="encode.delivery_postal_code" :class="errors[0] ? 'warning-border':''" autocomplete="new-password":maxlength="max1">
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                <!--   <ValidationProvider
                      name="city"
                      rules="required|max:30"
                      v-slot="{ errors }"
                      slim
                    > -->
                  <div class="label_and_element_wrapper">
                    <label>
                      City
                      <!-- <span>*</span> -->
                    </label>
                    <input type="text" placeholder="City Character Limit 30" v-model="encode.delivery_city" autocomplete="new-password" :maxlength="max">
                    
                  </div>
                  <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td>
                    <ValidationProvider
                      name="suburb"
                      rules="required|max:30"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Suburb
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Suburb Character Limit 30" v-model="encode.delivery_suburb" :class="errors[0] ? 'warning-border':''" autocomplete="new-password"  :maxlength="max">
                    
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="country"
                      rules="required|max:30"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Country
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Country Character Limit 30" v-model="encode.delivery_country" :class="errors[0] ? 'warning-border':''" autocomplete="new-password"  :maxlength="max">
                    
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="state/province"
                      rules="required|max:30"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      State/Province
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="State/Province Character Limit 30" v-model="encode.delivery_state" :class="errors[0] ? 'warning-border':''" autocomplete="new-password"  :maxlength="max">
                    
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="phone"
                      rules="required|max:20"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Phone
                       <span>*</span>
                    </label>
                    <input type="text" placeholder="Phone Character Limit 20" v-model="encode.delivery_phone" :class="errors[0] ? 'warning-border':''" autocomplete="new-password"  :maxlength="max4">
                    
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                      name="mobile"
                      rules="max:20"
                      v-slot="{ errors }"
                      slim
                    >
                  <div class="label_and_element_wrapper">
                    <label>
                      Mobile
                      <span></span>
                    </label>
                    <input type="text" placeholder="Mobile Character Limit 20" v-model="encode.delivery_mobile" :class="errors[0] ? 'warning-border':''" autocomplete="new-password"  :maxlength="max4">
                    
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                 <!--  <ValidationProvider
                      name="email"
                      rules="required|email|max:60"
                      v-slot="{ errors }"
                      slim
                    > -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Email
                      <!-- <span>*</span> -->
                    </label>
                    <input type="text" placeholder="Email Character Limit 60" v-model="encode.delivery_email" autocomplete="new-password" :maxlength="max2">
                    
                  </div>
                  <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Preferred Address 
                      <span></span>
                    </label>
                    <div class="preferred_address_box">
                      <ul v-if="is_preferred_address">
                        <li>
                          <span class="first_line">{{preferred_address.address_1}}</span>
                        </li>
                        <li>{{preferred_address.address_2}}</li>
                        <li>PO: {{preferred_address.postal_code}}</li>
                        <li>{{preferred_address.city}}</li>
                        <li>{{preferred_address.country}}</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <a class="insert_bt" :disabled="!is_preferred_address" @click="populatePreferredAddress">Insert</a>
                </td>
              </tr>
            </table>
          </div>
        </section>
      </div>
    </div>
    </ValidationObserver>
    <section class="centered" v-if="!loading">
        <a class="link_bt bt_cancel" @click="$router.push('/pages/Dashboard')">Cancel</a>
        <a class="link_bt bt_save" @click="submit" :disabled="submit_btn.disabled">{{submit_btn.value}}</a>
      </section>
    <div class="black-overlay" v-if="address_book.pickup || address_book.delivery">
      <div class="box-modal">
        <div class="body">
          <AddressBookModal 
              v-if="address_book.pickup" 
              @closeModal="closeModal" 
              type="pickup"
              @selectedAddress="populatePickupAddress"
            />
          <AddressBookModal 
            v-if="address_book.delivery" 
            @closeModal="closeModal" 
            type="delivery" 
            :category="encode.category"
            @selectedAddress="populateDeliveryAddress"
          />
        </div>
      </div>
    </div>
    <div class="black-overlay" v-show="showTransmittalSlip">
      <div class="box-modal">
        <div class="body">
          <TransmittalSlip v-show="showTransmittalSlip" @closeModal="showTransmittalSlip = false"/>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import AddressBookModal from "./AddressBookModal.vue";
import TransmittalSlip from "./TransmittalSlip.vue";
export default {
  name: "Encoding",
  components: {
    AddressBookModal,
    TransmittalSlip
  },
  computed:{
    filteredPickupAddress(){
      return this.address_list.filter(element => {
        if(element.name.toLowerCase().includes(this.encode.pickup_name.toLowerCase())){
          return element;
        }
      })
    },
    filteredDeliveryAddress(){
      return this.address_list.filter(element => {
        if(element.category == this.encode.category && element.name.toLowerCase().includes(this.encode.delivery_name.toLowerCase())){
          return element;
        }
      })
    }
  },
  data() {
    return {
      isDisabled: false,
      showTransmittalSlip: false,
      showEditContact: false,
      max: 30,
      max1: 10,
      max2: 60,
      max3: 70,
      max4: 20,
     
      encode:{
        service_id:"",
        category:"internal",
        pickup_name:"" ,
        pickup_company: "",
        pickup_branch: "",
        pickup_department: "",
        cost_center_id:"",
        pickup_address_1: "",
        pickup_address_2: "",
        pickup_postal_code: "",
        pickup_city: "",
        pickup_suburb: "",
        pickup_state: "",
        pickup_country: "",
        pickup_phone: "",
        pickup_mobile: "",
        pickup_email: "",
        item_reference:"",
        item_qty:1,
        pickup_instructions:"",
        goods_description:"",
        delivery_name:"",
        delivery_company:"",
        delivery_branch:"",
        delivery_department:"",
        delivery_address_1:"",
        delivery_address_2:"",
        delivery_postal_code:"",
        delivery_city:"",
        delivery_suburb:"",
        delivery_state:"",
        delivery_country:"",
        delivery_phone:"",
        delivery_mobile:"",
        delivery_email:""
      },
      address_list:[],
      is_preferred_address:false,
      preferred_address:{
        name:"",
        department:{
          department_name:""
        },
        branch_name:"",
        company_name:"",
        address_1:"",
        address_2:"",
        postal_code:"",
        city:"",
        suburb:"",
        state:"",
        country:"",
        phone:"",
        mobile:"",
        email:""
      },
      services:[],
      cost_centers:[],
      address_book:{
        pickup:false,
        delivery:false
      },
      submit_btn:{
        disabled:false,
        value:"SAVE"
      },
      show_pickup_suggestion:false,
      show_destination_suggestion:false,
      loading:true
    };
  },
  created(){
    this.$emit("breadCrumbData",["Encode"]);
    if(this.$route.params.id){
      /* works in edit encode */
      this.fetchCourier();
    }else{
      /* works in create encode */
      this.fetchUserData();
    }
    this.fetchService();
    this.fetchCostCenter();
    this.fetchAddresses();
  },
  methods:{
    hideSuggestions(){
      setTimeout(() => {
        this.show_pickup_suggestion = false;
        this.show_destination_suggestion = false;
      },200);
    },
    fetchCourier(){
      this.loading = true;
        console.log(this.$route.params.id);
      this.axios.get("/api/courier/domestic/"+ this.$route.params.id)
      .then(response => {
        console.log(response);
          this.encode.pickup_name = response.data.courier.pickup_name;
          this.encode.pickup_department = response.data.courier.pickup_department;
          this.encode.pickup_branch = response.data.courier.pickup_branch;
          this.encode.pickup_company = response.data.courier.pickup_company;
          this.encode.pickup_address_1 = response.data.courier.pickup_address_1;
          this.encode.pickup_address_2 = response.data.courier.pickup_address_2;
          this.encode.pickup_postal_code = response.data.courier.pickup_postal_code;
          this.encode.pickup_city = response.data.courier.pickup_city;
          this.encode.pickup_suburb = response.data.courier.pickup_suburb;
          this.encode.pickup_state = response.data.courier.pickup_state;
          this.encode.pickup_country = response.data.courier.pickup_country;
          this.encode.pickup_phone = response.data.courier.pickup_phone;
          this.encode.pickup_mobile = response.data.courier.pickup_mobile;
          this.encode.pickup_email = response.data.courier.pickup_email;
          this.encode.service_id = response.data.courier.service_id;
          this.encode.cost_center_id = response.data.courier.cost_center_id;
          this.encode.item_qty = response.data.courier.item_qty;
          this.encode.pickup_instructions = response.data.courier.pickup_instructions;
          this.encode.item_reference = response.data.courier.item_reference;
          this.encode.pickup_instructions = response.data.courier.pickup_instructions;
          this.encode.goods_description = response.data.courier.goods_description;
          this.encode.delivery_name = response.data.courier.delivery_name;
          this.encode.delivery_company = response.data.courier.delivery_company;
          this.encode.delivery_branch = response.data.courier.delivery_branch;
          this.encode.delivery_department = response.data.courier.delivery_department;
          this.encode.delivery_address_1 = response.data.courier.delivery_address_1;
          this.encode.delivery_address_2 = response.data.courier.delivery_address_2;
          this.encode.delivery_postal_code = response.data.courier.delivery_postal_code;
          this.encode.delivery_city = response.data.courier.delivery_city;
          this.encode.delivery_suburb = response.data.courier.delivery_suburb;
          this.encode.delivery_state = response.data.courier.delivery_state;
          this.encode.delivery_country = response.data.courier.delivery_country;
          this.encode.delivery_phone = response.data.courier.delivery_phone;
          this.encode.delivery_mobile = response.data.courier.delivery_mobile;
          this.encode.delivery_email = response.data.courier.delivery_email;
          this.loading = false;
      })
      .catch(error =>{
        this.loading = false;
      });
    },
    fetchUserData(){
      this.loading = true;
      this.axios.get("/api/user/data")
      .then(response => {
        console.log(response);
          this.encode.pickup_name = response.data.msg.name;
          this.encode.pickup_department = response.data.msg.department_id;
          this.encode.pickup_branch = response.data.msg.branch ? response.data.msg.branch.name : "";
          this.encode.cost_center_id = response.data.msg.cost ? response.data.msg.cost.id : "";
          this.encode.pickup_company = response.data.msg.company.name;
          this.encode.pickup_address_1 = response.data.msg.address_1;
          this.encode.pickup_address_2 = response.data.msg.address_2;
          this.encode.pickup_postal_code = response.data.msg.postal_code;
          this.encode.pickup_city = response.data.msg.city;
          this.encode.pickup_suburb = response.data.msg.suburb;
          this.encode.pickup_state = response.data.msg.state;
          this.encode.pickup_country = response.data.msg.country;
          this.encode.pickup_phone = response.data.msg.phone;
          this.encode.pickup_mobile = response.data.msg.mobile;
          this.encode.pickup_email = response.data.msg.email;
          this.loading = false;
      })
      .catch(error =>{
          this.toast.error(error);
      });
    },
    fetchCostCenter(){
      this.axios.get("api/cost")
      .then(response => {
        this.cost_centers = response.data.cost_centers;
      })
      .catch(error => {
        console.log(error);
      });
    },
    fetchService(){
      this.axios.get("/api/provider/services/list")
      .then(response => {
        this.services = response.data.msg.servicenames;
        if(!this.$route.params.id && this.services.length > 0){
          this.encode.service_id = this.services[0].id;
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    populatePickupAddress(value){
      console.log(value);
      this.encode.pickup_name = value.name;
      this.encode.pickup_department = value.department_id;
      this.encode.pickup_branch = value.branch_name;
      this.encode.pickup_company = value.company_name;
      this.encode.pickup_address_1 = value.address_1;
      this.encode.pickup_address_2 = value.address_2;
      this.encode.pickup_postal_code = value.postal_code;
      this.encode.pickup_city = value.city;
      this.encode.pickup_suburb = value.suburb;
      this.encode.pickup_state = value.state;
      this.encode.pickup_country = value.country;
      this.encode.pickup_phone = value.phone;
      this.encode.pickup_mobile = value.mobile;
      this.encode.pickup_email = value.email;
      this.show_pickup_suggestion = false;
    },
    populateDeliveryAddress(value){
      console.log(value);
      this.encode.delivery_name = value.name;
      this.encode.delivery_company = value.company_name;
      this.encode.delivery_branch = value.branch_name;
      this.encode.delivery_department = value.department_id;
      this.encode.delivery_address_1 = value.address_1;
      this.encode.delivery_address_2 = value.address_2;
      this.encode.delivery_postal_code = value.postal_code;
      this.encode.delivery_city = value.city;
      this.encode.delivery_suburb = value.suburb;
      this.encode.delivery_state = value.state;
      this.encode.delivery_country = value.country;
      this.encode.delivery_phone = value.phone;
      this.encode.delivery_mobile = value.mobile;
      this.encode.delivery_email = value.email;
      console.log(value.preferred);
      if(value.preferred){
        this.is_preferred_address = true;
        this.preferred_address = value.preferred;
      }else{
        this.is_preferred_address = false;
      }
      this.show_destination_suggestion = false;
    },
    fetchAddresses(){
      this.axios.post("api/address/list")
        .then(response => {
          this.address_list = response.data.msg;
        })
        .catch(error => {
          console.log(error);
        });
    },
    populatePreferredAddress(){
      this.populateDeliveryAddress(this.preferred_address);
    },
    async submit(){
          console.log(this.encode);
        this.$refs.observer.validate();
        this.submit_btn.disabled = true;
        this.submit_btn.value = "SAVING...";
        if(this.$route.params.id){
          this.axios.put("/api/encode/"+ this.$route.params.id, this.encode)
          .then(response => {
            this.toast.success(response.data.msg);
            this.$router.push({ path : '/pages/ViewEncodePending' });
            this.submit_btn.disabled = false;
            this.submit_btn.value = "SAVE";
          })
          .catch(error =>{
            console.log(error);
            this.submit_btn.disabled = false;
            this.submit_btn.value = "SAVE";
          });
        }else{
          console.log(this.encode);
          this.axios.post("/api/encode/create",this.encode)
          .then(response => {
            this.toast.success(response.data.msg);
            this.submit_btn.disabled = false;
            this.submit_btn.value = "SAVE";
            this.$router.push("/pages/ViewEncodePending");
          })
          .catch(error =>{
            console.log(error);
            this.submit_btn.disabled = false;
            this.submit_btn.value = "SAVE";
          });
        }
    },
    addressSelected(value){
        console.log(value); // You will get the address here
    },
    viewAddressBook(type){
      Object.keys(this.address_book).map(element => {
        this.address_book[element] = false;
      });
      this.address_book[type] = true;
    },
    closeModal(value){
      this.address_book[value.type] = false;
    }
  }
};
</script>
<style lang="scss">
.encoding_form_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .sub_sections {
    width: 100%;
    padding: 0 10px 0 0;
    &:last-child {
      padding: 0 0 0 10px;
    }
  }
}

.title_image {
  display: block;
  margin: auto;
}

a.insert_bt {
  display: block;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #ccc;
  color: #333;
  margin: 5px 15px auto auto;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid #666;
  font-size: 14px;
}
.preferred_address_box {
  display: block;
  text-align: left;
  font-size: 16px;
  padding: 5px 20px;
  width: 100%;
  height: 357px;
  background: #fff;
  border: 1px solid #ccc;
  ul li {
    padding: 5px 0;
  }
  .first_line {
    font-size: 16px;
  }
}

.bt_edit_contact {
  display: block;
  background: #004a7c;
  color: #fff;
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 14px;
  font-weight: 600;
}
.bt_edit_contact.show_addressbook {
  position: absolute;
  right: 10px;
  top: -10px;
  left: auto;
}
</style>